export const playerList = [
  {
    name: "Jonathan Huberdeau",
    pos: "F",
    CK: 62,
    FG: 47,
    DI: 73,
    SK: 83,
    ST: 72,
    EN: 73,
    DU: 90,
    PH: 81,
    FO: 49,
    PA: 78,
    SC: 65,
    DF: 63,
    PS: 77,
    EX: 84,
    LD: 88,
    OV: 70,
    age: 31,
  },
  {
    name: "Mason Marchment",
    pos: "F",
    CK: 71,
    FG: 51,
    DI: 68,
    SK: 76,
    ST: 87,
    EN: 66,
    DU: 84,
    PH: 61,
    FO: 49,
    PA: 70,
    SC: 70,
    DF: 74,
    PS: 78,
    EX: 62,
    LD: 60,
    OV: 68,
    age: 29,
  },
  {
    name: "Alex Iafallo",
    pos: "F",
    CK: 58,
    FG: 46,
    DI: 88,
    SK: 77,
    ST: 70,
    EN: 67,
    DU: 87,
    PH: 68,
    FO: 49,
    PA: 64,
    SC: 66,
    DF: 81,
    PS: 63,
    EX: 72,
    LD: 68,
    OV: 67,
    age: 30,
  },
  {
    name: "Ondrej Palat",
    pos: "F",
    CK: 66,
    FG: 47,
    DI: 77,
    SK: 80,
    ST: 65,
    EN: 68,
    DU: 73,
    PH: 72,
    FO: 48,
    PA: 65,
    SC: 64,
    DF: 75,
    PS: 63,
    EX: 81,
    LD: 85,
    OV: 67,
    age: 33,
  },
  {
    name: "Josh Anderson",
    pos: "F",
    CK: 75,
    FG: 54,
    DI: 67,
    SK: 81,
    ST: 84,
    EN: 68,
    DU: 81,
    PH: 62,
    FO: 49,
    PA: 58,
    SC: 66,
    DF: 59,
    PS: 70,
    EX: 73,
    LD: 70,
    OV: 64,
    age: 30,
  },
  {
    name: "Marcus Foligno",
    pos: "F",
    CK: 86,
    FG: 73,
    DI: 64,
    SK: 72,
    ST: 84,
    EN: 64,
    DU: 63,
    PH: 59,
    FO: 49,
    PA: 56,
    SC: 56,
    DF: 73,
    PS: 55,
    EX: 83,
    LD: 87,
    OV: 64,
    age: 32,
  },
  {
    name: "Alexander Barabanov",
    pos: "F",
    CK: 59,
    FG: 46,
    DI: 79,
    SK: 75,
    ST: 62,
    EN: 71,
    DU: 63,
    PH: 71,
    FO: 48,
    PA: 62,
    SC: 59,
    DF: 56,
    PS: 73,
    EX: 60,
    LD: 59,
    OV: 62,
    age: 30,
  },
  {
    name: "Connor Brown",
    pos: "F",
    CK: 59,
    FG: 45,
    DI: 82,
    SK: 72,
    ST: 61,
    EN: 65,
    DU: 70,
    PH: 59,
    FO: 48,
    PA: 57,
    SC: 52,
    DF: 69,
    PS: 54,
    EX: 73,
    LD: 69,
    OV: 60,
    age: 30,
  },
  {
    name: "Devon Toews",
    pos: "D",
    CK: 61,
    FG: 46,
    DI: 86,
    SK: 82,
    ST: 69,
    EN: 87,
    DU: 95,
    PH: 76,
    FO: 47,
    PA: 75,
    SC: 61,
    DF: 87,
    PS: 60,
    EX: 70,
    LD: 65,
    OV: 73,
    age: 30,
  },
  {
    name: "Brent Burns",
    pos: "D",
    CK: 49,
    FG: 46,
    DI: 79,
    SK: 82,
    ST: 89,
    EN: 84,
    DU: 94,
    PH: 84,
    FO: 48,
    PA: 70,
    SC: 63,
    DF: 78,
    PS: 73,
    EX: 99,
    LD: 85,
    OV: 72,
    age: 39,
  },
  {
    name: "Jamie Oleksiak",
    pos: "D",
    CK: 71,
    FG: 54,
    DI: 70,
    SK: 72,
    ST: 97,
    EN: 78,
    DU: 93,
    PH: 61,
    FO: 47,
    PA: 57,
    SC: 51,
    DF: 81,
    PS: 51,
    EX: 75,
    LD: 71,
    OV: 66,
    age: 31,
  },
  {
    name: "Jared Spurgeon",
    pos: "D",
    CK: 51,
    FG: 46,
    DI: 79,
    SK: 82,
    ST: 46,
    EN: 80,
    DU: 63,
    PH: 75,
    FO: 46,
    PA: 56,
    SC: 56,
    DF: 79,
    PS: 56,
    EX: 85,
    LD: 95,
    OV: 66,
    age: 34,
  },
  {
    name: "Nick Seeler",
    pos: "D",
    CK: 72,
    FG: 57,
    DI: 65,
    SK: 68,
    ST: 77,
    EN: 69,
    DU: 75,
    PH: 63,
    FO: 47,
    PA: 52,
    SC: 48,
    DF: 68,
    PS: 48,
    EX: 65,
    LD: 62,
    OV: 61,
    age: 31,
  },
];
