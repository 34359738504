export const goalieList = [
  {
    name: "Antti Raanta",
    SK: 69,
    DU: 65,
    EN: 71,
    SZ: 61,
    AG: 82,
    RB: 75,
    SC: 78,
    HS: 78,
    RT: 78,
    PH: 75,
    PS: 73,
    EX: 74,
    LD: 74,
    OV: 74,
    AGE: 35,
  },
  {
    name: "Chris Driedger",
    SK: 65,
    DU: 57,
    EN: 56,
    SZ: 85,
    AG: 64,
    RB: 68,
    SC: 70,
    HS: 62,
    RT: 60,
    PH: 78,
    PS: 57,
    EX: 59,
    LD: 59,
    OV: 64,
    AGE: 30,
  },
];
